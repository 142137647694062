import { notDesktop, styled } from "@obvio/app";
import { Grid, Stack } from "@obvio/ui";

import { FloatImage } from "./FloatImage";
import { ImgWrapHigh, ImgWrapLow } from "./FullSection";

import type { ImageAsset } from "@obvio/app";
import type { ReactElement } from "react";

type ImagesGridProps = {
  images: ImageAsset[];
};

const ImgWrapFull = styled.div`
  grid-row: span 2;
  width: 100%;
  aspect-ratio: 1/ 1.25;
`;

const EndStack = styled(Stack)`
  justify-content: flex-end;
`;

const AlignedStack = styled(Stack)`
  align-items: baseline;
`;

const Wrap = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`;

const Half = styled.div`
  width: 50%;
  @media ${notDesktop} {
    width: 100%;
  }
`;

const Small = styled.div`
  width: 30%;
  @media ${notDesktop} {
    width: 100%;
  }
`;

export function ImagesGrid({ images }: ImagesGridProps): ReactElement {
  return (
    <Wrap templateColumns="1fr 1fr" gap="medium">
      <ImgWrapFull>
        <FloatImage img={images[0]} />
      </ImgWrapFull>
      <EndStack>
        <div style={{ width: "35%" }}>
          <ImgWrapLow>
            <FloatImage img={images[1]} />
          </ImgWrapLow>
        </div>
        <div style={{ width: "45%" }}>
          <ImgWrapHigh>
            <FloatImage img={images[2]} />
          </ImgWrapHigh>
        </div>
      </EndStack>
      <Half>
        <ImgWrapHigh>
          <FloatImage img={images[3]} />
        </ImgWrapHigh>
      </Half>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Small>
          <ImgWrapLow>
            <FloatImage img={images[4]} />
          </ImgWrapLow>
        </Small>
      </div>
      <AlignedStack>
        <div style={{ width: "45%" }}>
          <ImgWrapHigh>
            <FloatImage img={images[5]} />
          </ImgWrapHigh>
        </div>

        <div style={{ width: "35%" }}>
          <ImgWrapLow>
            <FloatImage img={images[6]} />
          </ImgWrapLow>
        </div>
      </AlignedStack>
    </Wrap>
  );
}
